import React from "react"
import { Container, Grid, Box, TextField, Button } from "@material-ui/core"

import LayoutComponent from "../components/layout.component"
import SEO from "../components/seo.component"

const Contact = ({ location }) => {
  return (
    <LayoutComponent>
      <SEO title="Contact" canonical={location.href} />
      <Box pt={5} pb={5}>
        <Container maxWidth="xl">
          <Grid
            container
            spacing={5}
            direction="row"
            alignItems="center"
            justify="center"
          >
            <Grid item md={6}>
              <img
                src={require("../images/contact.jpg")}
                alt="contact"
                width="100%"
              />
            </Grid>
            <Grid item md={6}>
              <form noValidate style={{ width: "100%" }}>
                <Grid
                  container
                  spacing={4}
                  direction="row"
                  alignItems="center"
                  justify="center"
                >
                  <Grid item xs={12}>
                    <TextField
                      name="firstName"
                      variant="outlined"
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                      color="primary"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="mobilenumber"
                      label="Mobile Number"
                      name="mobilenumber"
                      color="primary"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      color="primary"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      rows={4}
                      multiline
                      id="message"
                      label="Message"
                      name="message"
                      color="primary"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="outlined">Submit</Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </LayoutComponent>
  )
}

export default Contact
